$(document).on('click', '.js-cart-add-item', function (e) {
    var dataString = $($(this).data('form')).serialize();
    var $qty = $($(this).data('form')).find('[name=qty]');
    var qty = $qty.val();
    if (!qty || qty < 1) {
        alertError(App.text.please_input_qty)
    }
    $.ajax({
        url: App.homeUrl + '/api/cart/add',
        method: 'POST',
        data: dataString,
    }).done(function(response){
        // console.log(response);
        cartLayoutUpdate(response);
        alertSuccess(response.message);
    }).fail(function (response) {
        // console.log(response.status, response.responseJSON);
        if (response.status==422) {
            $errors = '';
            $.each(response.responseJSON.errors, function(index, msgs) {
                $errors += msgs.join('<br>')+'<br>';
            })
            alertError($errors);
        } else {
            // if (response.status==400) {
            //     $qty.val(response.responseJSON.max_qty);
            // }
            alertError(response.responseJSON.message);
        }
    });
    return false;
}).on('click', '.js-qty-control', function (e) {
    var action = $(this).data('action');
    var numField = $(this).siblings('.js-qty');
    var max = numField.data('max');
    var num = parseInt(numField.val());
    if (action == 'less') {
        if (num > 1) {
            numField.val(num-1);
        } else {
            numField.val(1);
        }
    } else if (action == 'add') {
        if (max && num>=max) {
            return false;
        }
        if (num>0) {
            numField.val(num+1);
        } else {
            numField.val(1);
        }
    }
    numField.trigger('change');
    return false;
}).on('change', '.js-cart-check', function (e) {
    var sku_id = $(this).data('sku');
    if (sku_id) {
        $.post(
            App.homeUrl + '/api/cart/update_item_checked', {
                _token: App.csrfToken,
                sku_id: sku_id,
                checked: $(this).is(':checked')?1:0,
            },
            function (response) {
                cartLayoutUpdate(response);
            }
        );
    }
}).on('click', '.js-cart-delete-item', function (e) {
    $.post(
        App.homeUrl + '/api/cart/delete', {
            _token: App.csrfToken,
            props: $(this).data('props')
        },
        function (response) {
            cartLayoutUpdate(response);
        }
    );
    return false;
}).on('click', '.js-cart-copy-buyer', function (e) {
    $('[data-copy-from]').each(function () {
       var t = $(this).data('copy-from');
       $(this).val($(t).val()).trigger('change');
    });
    return false;
}).on('change', '.js-cart-update-form-data', function (e) {
    $.post(
        App.homeUrl + '/api/cart/update_form_data', {
            _token: App.csrfToken,
            name: $(this).attr('name'),
            val: $(this).val(),
        },
        function (response) {
        }
    ).fail(function (response) {
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-cart-update-item-qty', function (e) {
    var props = $(this).data('props');
    var $that = $(this);
    if (props) {
        $.post(
            App.homeUrl + '/api/cart/update_qty', {
                _token: App.csrfToken,
                props: props,
                qty: $(this).val(),
            },
            function (response) {
                cartLayoutUpdate(response);
            }
        ).fail(function (response) {
            $that.parent('.input-group').addClass('has-validation');
            $that.addClass('is-invalid');
            alertError(response.responseJSON.message)
        });
    }
}).on('change', '.js-cart-update-shipping', function (e) {
	if ($(this).is('select')) {
		var shipping_id = $(this).val();
	} else {
		var shipping_id = $('.js-cart-update-shipping:checked').val();
	}
    $.post(
        App.homeUrl+'/api/cart/update_shipping', {
            _token: App.csrfToken,
            shipping_id: shipping_id,
        },
        function (response) {
            $('#cart-payment-holder').html(response.cartPaymentLayout);
            $('#cart-sum-holder').html(response.cartSumLayout);
        }
    ).fail(function (response) {
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-cart-update-shipping-type', function (e) {
	if ($(this).is('select')) {
		var type = $(this).val();
	} else {
		var type = $('.js-cart-update-shipping-type:checked').val();
	}
    $.post(
        App.homeUrl+'/api/cart/update_shipping_type', {
            _token: App.csrfToken,
            type: type,
        },
        function (response) {
            $('#cart-sum-holder').html(response.cartSumLayout);
        }
    ).fail(function (response) {
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-cart-update-payment', function (e) {
    if ($(this).is('select')) {
		var payment_id = $(this).val();
	} else {
		var payment_id = $('.js-cart-update-payment:checked').val();
	}
    $.post(
        App.homeUrl+'/api/cart/update_payment', {
            _token: App.csrfToken,
            payment_id: payment_id,
        },
        function (response) {
            $('#cart-sum-holder').html(response.cartSumLayout);
        }
    ).fail(function (response) {
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-cart-update-coupon', function (e) {
    $.post(
        App.homeUrl+'/api/cart/update_coupon', {
            _token: App.csrfToken,
            coupon: $(this).val(),
        },
        function (response) {
            $('#cart-sum-holder').html(response.cartSumLayout);
            alertSuccess(response.message);
        }
    ).fail(function (response) {
        $('#cart-sum-holder').html(response.cartSumLayout);
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-cart-update-bonus', function (e) {
    if ($(this).is('[type="checkbox"]')) {
        var bonus = $(this).is(':checked')?parseInt($(this).val()):0;
    } else {
        var bonus = parseInt($(this).val());
    }
    $.post(
        App.homeUrl+'/api/cart/update_bonus', {
            _token: App.csrfToken,
            bonus: bonus,
        },
        function (response) {
            $('#cart-sum-holder').html(response.cartSumLayout);
        }
    ).fail(function (response) {
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-cart-update-remote', function (e) {
    var region_id = $(this).val();
    $.post(
        App.homeUrl+'/api/cart/update_remote', {
            _token: App.csrfToken,
            region_id: region_id,
        },
        function (response) {
            $('#cart-sum-holder').html(response.cartSumLayout);
        }
    ).fail(function (response) {
        $('#cart-sum-holder').html(response.cartSumLayout);
        alertError(response.responseJSON.message)
    });
}).on('change', '.js-change-sku', function (e) {
    var dataString = $($(this).data('form')).serialize();
    var holder = $(this).data('holder');
    console.log(dataString, holder);
    $.ajax({
        url: App.homeUrl + '/api/cart/change_sku',
        method: 'POST',
        data: dataString,
    }).done(function(response){
        // console.log(response);
        $(holder).html(response.layout);
    }).fail(function (response) {
        // console.log(response.status, response.responseJSON);
        if (response.status==422) {
            $errors = '';
            $.each(response.responseJSON.errors, function(index, msgs) {
                $errors += msgs.join('<br>')+'<br>';
            })
            alertError($errors);
        } else {
            // if (response.status==400) {
            //     $qty.val(response.responseJSON.max_qty);
            // }
            alertError(response.responseJSON.message);
        }
    });
});

function cartLayoutUpdate(response) {
    $('#cart-holder').html(response.cartLayout);
    if ($('#top-cart-holder').length && response.topCartLayout) {
        $('#top-cart-holder').html(response.topCartLayout);
    }
}
function alertSuccess(txt) {
    Swal.fire({
        html: txt,
        icon: 'success',
        confirmButtonText: App.text.ok
    });
}
function alertError(txt) {
    Swal.fire({
        html: txt,
        icon: 'error',
        confirmButtonText: App.text.close
    });
}
